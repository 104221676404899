import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "../components/Container"
import {css} from "@emotion/core"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page non trouvée" />
    <Container>
      <div
        css={css`
          margin-top:150px;
          margin-bottom:150px;
          text-align:center;
        `}
      >
        <h1>Cette page n'existe pas.</h1>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
